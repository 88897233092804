// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-kt-toggle-entity-dialog',
    templateUrl: './toggle-entity-dialog.component.html'
})
export class ToggleEntityDialogComponent implements OnInit {
    viewLoading = false;

    constructor(
        public dialogRef: MatDialogRef<ToggleEntityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onYesClick(): void {
        this.viewLoading = true;
        setTimeout(() => {
            this.dialogRef.close(true);
        }, 1000);
    }
}
