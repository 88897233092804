import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppHelper } from '../../../_helpers';
import { Router } from '@angular/router';
// RxJS
import { Observable, of, BehaviorSubject } from 'rxjs';
import { mergeMap, map, catchError, finalize } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/curd';
import { environment } from '../../../../../../environments/environment';
import { ScheduleHTTPService } from './schedules-http/schedules-http.services';

const API_BASE_URL = `${AppHelper.getApiBaseUrl()}`;


@Injectable()
export class ScheduleServices {
  private isLoadingSubject: BehaviorSubject<boolean>;

  lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
  constructor(
    private scheduleHTTPService: ScheduleHTTPService,
  ) { }

  getUserSchedule(token, username, startDate?, endDate?) {
    return this.scheduleHTTPService.getUserSchedule(token, username, startDate, endDate).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

}
