// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
selector: 'app-kt-submit-entity-dialog',
templateUrl: './submit-entity-dialog.component.html'
})
export class SubmitEntityDialogComponent implements OnInit {
viewLoading = false;

constructor(
public dialogRef: MatDialogRef<SubmitEntityDialogComponent>,
@Inject(MAT_DIALOG_DATA) public data: any
) { }

ngOnInit() { // this method is required for the component if it is empty also
}

onNoClick(): void {
this.dialogRef.close();
}

onYesClick(): void {
this.viewLoading = true;
setTimeout(() => {
this.dialogRef.close(true);
}, 2500);
}
}
