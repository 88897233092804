import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../users/Auth/_services/auth.service';
import { AuthHTTPService } from '../../../users/Auth/_services/auth-http';
import { AuthModel } from '../../../users/Auth/_models/auth.model';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopupComponent } from 'src/app/modules/errors/error-popup/error-popup.component';

@Injectable()
export class InterceptService implements HttpInterceptor {
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    api_list: string[] = [
        'api/v2/alerts/voicemessage',
        'api/v2/auth/login',
        'api/v2/auth/ssologin',
        'api/v2/auth/validateaccount',
        'api/v2/auth/validatelogin',
        'api/v2/auth/createpassword',
        'api/v2/auth/updatepassword',
        'api/v2/auth/resetpassword',
        'api/v2/auth/refreshtoken',
        'api/v2/auth/refreshlogin',
        'api/v2/auth/acknowledge/token',
        'api/v2/auth/assign/token',
        'api/v2/auth/close/token',
        'api/v2/auth/escalate/token',
        'api/v2/auth/message/token',
        'api/v2/auth/activate',
        'api/v2/auth/time_zone'
    ];

    constructor(public authService: AuthService,
        public authHTTPService: AuthHTTPService,
        public dialog: MatDialog) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let isvalid = this.validatemailUrl(this.api_list,request.url);
        if (!isvalid && !localStorage.getItem('token')) {
            this.authService.logoutFromAuthGaurd();
            return throwError('Invalid JWT Token');
        } else {
            if (localStorage.getItem('token')) {
                request = this.addToken(request, localStorage.getItem('token'));
            }

            return <any>next.handle(request).pipe(catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(request, next);
                } else if (error instanceof HttpErrorResponse && (error.status == 500 || error.status == 501 || error.status == 503 || error.status == 504 || error.status == 505 || error.status == 506 || error.status == 507 || error.status == 508 || error.status == 509 || error.status == 510 || error.status == 511)) {
                    const dailogRef = this.dialog.open(ErrorPopupComponent, { width: '500px', disableClose: true });
                } else {
                    return throwError(error);
                }
            }));
        }
    }

    validatemailUrl(api_list: string[], api_url: string): boolean {
        return api_list.some((ele) => api_url.includes(ele));
    }
    
    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        const isTokenExpired = this.authService.isTokenExpired(localStorage.getItem('token'));
        if (!isTokenExpired && !this.isRefreshing && localStorage.getItem('refresh_token')) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const refresh_token = { 'refresh_token': localStorage.getItem('refresh_token') }
            return this.authHTTPService.refreshToken(refresh_token).pipe(
                switchMap((user: any) => {
                    localStorage.setItem('token', user.token);
                    this.isRefreshing = false;
                    const authData = JSON.parse(
                        localStorage.getItem(this.authLocalStorageToken)
                    );
                    authData.token = user.token;
                    authData.refresh_token = user.refresh_token;
                    this.setAuthFromLocalStorage(authData);
                    this.refreshTokenSubject.next(user.token);
                    return next.handle(this.addToken(request, user.token)).pipe(catchError(error => {
                        return throwError(error);
                    }));
                }),
                catchError((err) => {
                    console.log('From Intercept service - refresh token API call: ' + err);
                    this.isRefreshing = false;
                    if (err.url?.includes('auth/refreshtoken')) {
                        this.authService.removeItem();
                    }
                    return throwError(err);
                }));
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt)).pipe(catchError(error => {
                        return throwError(error);
                    }));;
                }));
        }
    }

    private setAuthFromLocalStorage(auth: AuthModel): boolean {
        if (auth?.token) {
            localStorage.setItem('token', auth.token);
            localStorage.setItem('refresh_token', auth.refresh_token);
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
            return true;
        }
        return false;
    }
}
