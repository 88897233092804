import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GroupMemberService {

  constructor(private http: HttpClient) { }

  getMemberByID(token, groupID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/groups/" + groupID + "/members");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  getAllUsers(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/users`, {
      headers: httpHeaders
    });
  }

  getAllSenders(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/users/senders`, {
      headers: httpHeaders
    });
  }

  getAllGroups(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/groups`, {
      headers: httpHeaders
    });
  }

  getUserByID(token , id){
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/groups/${id}/users_available`, {
      headers: httpHeaders
    });
  }

  getGroupsByID(token, id) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/groups/${id}/groups_available`, {
      headers: httpHeaders
    });
  }

  getAlMembersByID(token, groupID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/groups/" + groupID + "/members");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  addMember(token, groupID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/groups/${groupID}/members`, params, {
      headers: httpHeaders,
    });
  }

  updateMember(token, groupID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/groups/${groupID}/members/${params.member_type}/${params.member_id}`, params, {
      headers: httpHeaders,
    });
  }
  updateMemberSeqChange(token, groupID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    const body = {
      "sequence":params.sequence,
      "roles":params.roles
    }
    return this.http.put<any>(`${environment.apiUrl}/groups/${groupID}/members/${params.member_type}/${params.member_id}`, body, {
      headers: httpHeaders,
    });
  }

  deleteMember(token, groupID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/groups/${groupID}/members/${params.member_type}/${params.member_id}`, {
      headers: httpHeaders,
    });
  }
  //Ebison on 24/01/2021
  multideleteMember(token, groupID, params): Observable<any> {
    const data = {
      headers: new HttpHeaders({
        Authorization: `${token}`,
      }),
      body: params
    }
    return this.http.delete<any>(`${environment.apiUrl}/groups/${groupID}/members`,data );
  }
}