import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AdminPostmortemService {

  constructor(private http: HttpClient) { }

  getAllPostmortemsFields(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/postmortem_fields`, {
      headers: httpHeaders
    });
  }


  // getAllAlertTypes(token) {
  //   const httpHeaders = new HttpHeaders({
  //     Authorization: `${token}`,
  //   });
  //   return this.http.get<any>(`${environment.apiUrl}/alert_types`, {
  //     headers: httpHeaders
  //   });
  // }

  addPostmortem(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/postmortem_fields`, params, {
      headers: httpHeaders,
    });
  }

  updatePostmortem(token, pName, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/postmortem_fields/${pName}`, params, {
      headers: httpHeaders,
    });
  }

  deletePostmortem(token, pName): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/postmortem_fields/${pName}`, {
      headers: httpHeaders,
    });
  }
}


