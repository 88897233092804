import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AdminCustomAlertService {

  constructor(private http: HttpClient) { }

  getAllAlertTypes(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/alert_types`, {
      headers: httpHeaders
    });
  }

  getAlertFieldByTypeID(token, alertTypeID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/alert_types/${alertTypeID}/fields`, {
      headers: httpHeaders
    });
  }

  getAlertFieldForDeliveryAttempt(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/workflow_types/delivery_attempt/fields`, {
      headers: httpHeaders
    });
  }

  getAllAlertTypesByID(token, alertTypeID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/alert_types/" + alertTypeID);
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  getFieldsByATypeID(token, alertTypeID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/alert_types/" + alertTypeID + "/fields");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  addalertTypes(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/alert_types`, params, {
      headers: httpHeaders,
    });
  }

  updateAlertType(token, alertTypeID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/alert_types/${alertTypeID}`, params, {
      headers: httpHeaders,
    });
  }

  addAlertFields(token, alertTypeID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/alert_types/${alertTypeID}/fields`, params, {
      headers: httpHeaders,
    });
  }

  updateAlertFields(token, alertTypeID, alertFieldID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/alert_types/${alertTypeID}/fields/${alertFieldID}`, params, {
      headers: httpHeaders,
    });
  }

  deleteAlertField(token, alertTypeID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/alert_types/${alertTypeID}/fields/${params['alert_field_id']}`, {
      headers: httpHeaders,
    });
  }

  getAlertFieldDataType(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/alert_fields_data_type`, {
      headers: httpHeaders
    });
  }
}