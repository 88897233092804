import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AdminBridgeService {

  constructor(private http: HttpClient) { }

  getAllBridges(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/bridges`, {
      headers: httpHeaders
    });
  }

  getappAllBridges(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/bridges`, {
      headers: httpHeaders
    });
  }

  // getAllAlertTypes(token) {
  //   const httpHeaders = new HttpHeaders({
  //     Authorization: `${token}`,
  //   });
  //   return this.http.get<any>(`${environment.apiUrl}/alert_types`, {
  //     headers: httpHeaders
  //   });
  // }

  addBridge(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/bridges`, params, {
      headers: httpHeaders,
    });
  }

  updateBridge(token, bridgeID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/bridges/${bridgeID}`, params, {
      headers: httpHeaders,
    });
  }

  deleteBridge(token, bridgeID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/bridges/${bridgeID}`, {
      headers: httpHeaders,
    });
  }
}


