import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbilitiesService } from '../../abilities/abilities.service';
import { AuthHTTPService } from '../Auth/_services/auth-http';
import { AuthService } from '../Auth/_services/auth.service';
import * as dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

@Injectable({
  providedIn: 'root'
})

export class UserstorageService {

  constructor(  private authHttpService: AuthHTTPService, 
    private authService :AuthService,
    private abilitiesservices: AbilitiesService,
    private _router :Router) 
  { dayjs.extend(localeData);
    dayjs.extend(utc)
    dayjs.extend(timezone);}

  getuserstorage(){
    const userstorage = localStorage.getItem('userLocalStorageToken');
    if(userstorage && userstorage != "null" && userstorage != "\"null\"") {
      return userstorage;
    } else {
     this.authService.logoutFromAuthGaurd();
    }
  }
  getAccountExpired(){
      let local_account = localStorage.getItem('token-key');
      let account_name :any;
      let daycnt : any;
      let expireDate;
      let time_zone_name;
      if(local_account){
        account_name = JSON.parse(local_account);
      }
      this.abilitiesservices.expiryDateSubject.subscribe(res=>{
        if(res != undefined && res != null && res != "") {
         expireDate = res;
        };
      });
      this.abilitiesservices.accountTZIDSubject.subscribe(res=>{
        if(res != undefined && res != null && res != "" && account_name && expireDate && time_zone_name == undefined) {
          time_zone_name = res;
          let minDate : any = dayjs(dayjs().tz(time_zone_name));
          let DD = minDate.$D.toString().length >1 ? minDate.$D : `0${minDate.$D}`;
          let MM = minDate.$M.toString().length > 1 ? minDate.$M+1 : `0${minDate.$M+1}`;
          let YY = minDate.$y;
          let HH = minDate.$H.toString().length >1 ? minDate.$H : `0${minDate.$H}`;
          let mm = minDate.$m.toString().length >1 ? minDate.$m : `0${minDate.$m}`;
          let ss = minDate.$s.toString().length >1 ? minDate.$s : `0${minDate.$s}`;
          let minDateString = `${YY}-${MM}-${DD}T${HH}:${mm}:${ss}`
          let minDateToDate: any = new Date(minDateString)
          let maxDate : any = new Date(expireDate);
          daycnt = maxDate - minDateToDate;
          if(daycnt < 0) {
            this._router.navigate(['/'+account_name.account+'/expired-installation']);
            return false;
          }
        };
      });
  }
  getToken() {
    const localtoken = localStorage.getItem('token');
    if(localtoken && localtoken != "null" && localtoken != "\"null\"") {
      return localtoken;
    } else {
     this.authService.logoutFromAuthGaurd();
    }
  }
}
