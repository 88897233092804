import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WorkflowListService {
  // Ebison on 16/Feb/2022 Bug Fix: Set updated WF name in Bread crumbs
  workflowDetailsNameChange = new EventEmitter<string>();
  // End -- Ebison on 16/Feb/2022 Bug Fix: Set updated WF name in Bread crumbs
  constructor(private http: HttpClient) { }

  getAllWorkflows(token, searchBy, workflowtype, enabled, offset, sortBy, direction) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    param = param.append('limit', '70');
    if (searchBy)
      param = param.append('search', searchBy);
    if (workflowtype)
      param = param.append('workflowtype', workflowtype);
    if ((enabled || !enabled) && enabled != undefined)
      param = param.append('enabled', enabled);
    if (offset)
      param = param.append('offset', offset);
    if (sortBy)
      param = param.append('sortBy', sortBy);
    if (direction)
      param = param.append('sortDir', direction);
    return this.http.get<any>(`${environment.apiUrl}/workflows`, {
      headers: httpHeaders, params: param
    });
  }

  getWorkFlowByID(token, workflowID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/workflows/" + workflowID);
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  getAllAlertTypes(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/alert_types`, {
      headers: httpHeaders
    });
  }

  addWorkFlow(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/workflows`, params, {
      headers: httpHeaders,
    });
  }

  cloneWorkFlow(token, workflowId, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/workflows/${workflowId}/clone`, params, {
      headers: httpHeaders,
    });
  }

  updateWorkFlow(token, workflowID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/workflows/${workflowID}/basic`, params, {
      headers: httpHeaders,
    });
  }

  deleteWorkflow(token, workflowID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/workflows/${workflowID}`, {
      headers: httpHeaders,
    });
  }

  updateWorkFlowStatus(token, workflowID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/workflows/${workflowID}/status`, params, {
      headers: httpHeaders,
    });
  }

  getWorkflowTypes(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/workflow_types`, {
      headers: httpHeaders
    });
  }
  
}


