import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
import { SplashScreenService } from './_theme/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, ActivationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { LayoutService } from './_theme/core';
import { UserstorageService } from './_theme/core/users/userstorage/userstorage.service';
import { Userpilot} from 'userpilot';
import { AbilitiesService } from './_theme/core/abilities/abilities.service';
@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; 
  public interval: number;
  private userActivityChangeCallback: ($event) => void;
  public loading = false;
  public isRefreshToken = false;
  public isMouseMove = false;
  subdomainValue: any;
  loggedin_userID: any;
  show_tour: boolean;
  show_chat: boolean = false;
  show_chat_else: boolean = false;
  show_chat_if: boolean = false;
  constructor(
    private splashScreenService: SplashScreenService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private layoutService: LayoutService,
    private userservice: UserstorageService,
    private abilitiesServices : AbilitiesService,
    private _layout: LayoutService
  ) {
  }

  ngOnInit() {
    this.IntercomInit();
    this.initDoc360();
    this.dataDogInit();
    let prev_ev_url;
    this.subdomainValue = this._layout.getSubdomain();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.userservice.getAccountExpired();
        this.splashScreenService.hide();
        window.scrollTo(0, 0);
        if(this.show_tour){
          Userpilot.reload();
        }
        if(this.show_chat){
          (<any>window).Intercom("update");
        }
      }
      let path_detail;
      if (event instanceof ActivationStart) {
        let ev: any;
        ev = event.snapshot;
        let ev_url = ev?._routerState?.url;
        let _correctedLastPathIndex = ev?._correctedLastPathIndex;
        this.subdomainValue = this.subdomainValue? this.subdomainValue : 'org';
        if (event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path == event.snapshot.routeConfig.path) {
          path_detail = '/' + event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path;
        } else if ((event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path != event.snapshot.routeConfig.path) && event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path && event.snapshot.routeConfig.path) {
          path_detail = '/' + event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path + '/' + event.snapshot.routeConfig.path
        }
        if (path_detail) {
            prev_ev_url = path_detail;
            datadogRum.startView({
              name: path_detail
            });
        } else {
          let ev_url2 = ev_url; // to skip the else part call for auth module
          ev_url = ev_url?.replace('/' + this.subdomainValue, ''); // ?token= -> added for to restrict mail action url
          if (ev_url && !ev_url2.includes("org") && !ev_url2?.toLowerCase().includes("?token=") && prev_ev_url != ev_url && !event.snapshot.routeConfig?.children && _correctedLastPathIndex != -1) { // to get a back url // "event.snapshot.routeConfig?.children" it skip one loop
            datadogRum.startView({
              name: ev_url
            });
          }
        }
      }
    });
    this.unsubscribe.push(routerSubscription);
  }
  IntercomInit(){
    let script_code = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${environment.chat_tool_id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
    let script_tag = document.createElement("script");
    script_tag.type = "text/javascript";
    script_tag.innerHTML = script_code;
    let body = document.getElementById("kt_body");
    let layout = document.getElementsByTagName('app-layout')[0];
    body.insertBefore(script_tag, layout);
  }
  dataDogInit() {
    datadogRum.init({
      applicationId: environment.applicationId,
      clientToken: environment.clientToken,
      site: 'datadoghq.com',
      service:'aowebapp',
      env: environment.env,
      // Specify a version number to identify the deployed version of your application in Datadog 
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask-user-input',
      trackViewsManually: true,
    });

    Userpilot.initialize(environment.show_tour_productid);

    datadogRum.startSessionReplayRecording();
    let user_storage = localStorage.getItem('userLocalStorageToken');
    let subdomain = this.layoutService.getSubdomain();
    if(subdomain == ''){
      subdomain = localStorage.getItem('subdomain');
    }
    if(user_storage) {
      this.loggedin_userID = JSON.parse(user_storage);
      let ddg_user_id = datadogRum.getUser();
      if(this.loggedin_userID.user_id){
        if(ddg_user_id.user_id !== this.loggedin_userID.user_id){
          datadogRum.setUser({id:this.loggedin_userID.user_id,account:subdomain})
        }
      }
    }
    this.abilitiesServices.abilitiesSubject.subscribe(result =>{
      if(result && result?.show_tour == 1 && this.loggedin_userID?.user_id ){
        this.show_tour = result.show_tour == 1 ? true : false;
        Userpilot.identify(
          this.loggedin_userID.user_id,
          {
            name: this.loggedin_userID.first_name,
            account: subdomain
          });
      }
      const loginDt = JSON.parse(localStorage.getItem('token-key'));
      let officialemail = localStorage.getItem('email');
      if(result && (!this.show_chat_if && result.show_chat == 1)  && loginDt.user_hash ){
        this.show_chat_else = false;
        this.show_chat_if = result.show_chat == 1 ? true : false;
        this.show_chat = result.show_chat == 1 ? true : false;
        if(officialemail) {
          (<any>window).Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: environment.chat_tool_id,
            user_id: this.loggedin_userID.user_id,
            name: this.loggedin_userID.first_name+' '+this.loggedin_userID.last_name,
            hide_default_launcher: !this.show_chat,
            user_hash :loginDt.user_hash,
            account: subdomain,
            email:officialemail
            });

            if(officialemail){
              localStorage.removeItem('email');
            }
        } else {
          (<any>window).Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: environment.chat_tool_id,
            user_id: this.loggedin_userID.user_id,
            name: this.loggedin_userID.first_name+' '+this.loggedin_userID.last_name,
            hide_default_launcher: !this.show_chat,
            user_hash :loginDt.user_hash,
            account: subdomain
            });
        }
      } else {
        if(result && (!this.show_chat_else && result.show_chat == 0)){
          this.show_chat_if= false;
          this.show_chat = result.show_chat == 1 ? true : false;
          this.show_chat_else = result.show_chat == 0 ? true : false;
          (<any>window).Intercom('update', {
            hide_default_launcher: this.show_chat_else,
            });
        }
      }
    })
  }

  initDoc360() {
    let code = "(function (w,d,s,o,f,js,fjs) { " +
      "w['JS-Widget']=o;w[o] = w[o] || function () {(w[o].q = w[o].q || []).push(arguments)};" +
      "js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];" +
      "js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);" +
    "} (window, document, 'script', 'mw', 'https://cdn.document360.io/static/js/widget.js')); " +
    "mw('init', { apiKey: 'tusYiC7GcSm7yZIK9pDtyDgzjcBm564URYf1VDFzB2g79XYTJBTzXSULrTwLkoO5k6tNJzSQS6GSWDxVgAgHRWsTPbWqMioXYMgxHWNabvBE0Ky5Eu0lkQ1INmngXyB8CzEVzpdk/1XK3HPSwrxEZQ==' }); ";
    let s = document.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = code;
    let body = document.getElementById("kt_body");
    let layout = document.getElementsByTagName('app-layout')[0];
    body.insertBefore(s, layout);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}

