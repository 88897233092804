import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_theme/core/users/Auth/_services/auth.guard';

export const routes: Routes = [
  {
    path: 'org',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: ':subdomain/expired-installation',
    loadChildren: () =>
      import('./modules/expired-installation/expired-installation.module').then((m) => m.ExpiredInstallationModule),
  },
  {
    path: ':subdomain',
    loadChildren: () =>
      import('./modules/mailaction/mailaction.module').then((m) => m.MailactionModule),
  },
  { path: '', redirectTo: 'org/account', pathMatch: 'full' },
  { 
    path: '**', 
    canActivate: [AuthGuard],  
    loadChildren: () =>  import('./pages/layout.module').then((m) => m.LayoutModule), 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
