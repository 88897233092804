<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-progress">
			<!-- here can place a progress bar-->
			<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
		</div>
		<div class="kt-portlet__head-label">
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{ viewLoading ? data.waitDesciption : data.title}}
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<button mat-button (click)="onNoClick()">Cancel</button>&nbsp;
				<button mat-button class="btn-add" (click)="onYesClick()" cdkFocusInitial [disabled]="viewLoading">Submit</button>
			</div>
		</div>
	</div>
</div>
