import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AdminAuditTrailService {

  constructor(private http: HttpClient) { }

  getAuditTrail(token, from, to, auditItem, sortBy, direction) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    if (from)
      param = param.append('dateFrom', from);
    if (to)
      param = param.append('dateTo', to);
    if (auditItem)
      param = param.append('auditItem', auditItem);
    if (sortBy)
      param = param.append('sortBy', sortBy);
    if (direction)
      param = param.append('sortDir', direction);

    param = param.append('limit','100');  
    return this.http.get<any>(`${environment.apiUrl}/audit_trail`, {
      headers: httpHeaders, params: param
    });
  }

  getAuditItem(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/audit_items`, {
      headers: httpHeaders
    });
  }
}


