import {
    Directive,
    HostListener,
    Input,
    ElementRef,
    Renderer2
  } from '@angular/core';
  
  // tslint:disable-next-line:align
  @Directive({
    selector: '[appTrackScroll]'
  })
  export class TrackScrollDirective {
    @Input('appTrackScroll')
    private scrollName;
  
    state = 'hide';
  
    constructor(public el: ElementRef, private renderer: Renderer2) {}
  
    ngAfterViewInit() {
      this.highlightBox();
    }
  
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
      this.highlightBox();
    }
  
    highlightBox() {
      const componentPosition = this.el.nativeElement.offsetTop;
      const scrollPosition = window.pageYOffset;
      let margin = 400;
      if (scrollPosition >= componentPosition) {
        this.renderer.addClass(this.el.nativeElement, 'highlight');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'highlight');
      }
    }
  }
  