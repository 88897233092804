import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OutOfOfficeHTTPService {
  constructor(private http: HttpClient) { }

  AddOOf(name, token, params = {}) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.post(`${environment.apiUrl}/users/${name}/out_of_office`, params, { headers: httpHeaders });
  }

  getUserOutbound(token, username): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + username + "/out_of_office");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders,
    });
  }

  deleteOOfO(id, outofofficeid, token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/users/${id}/out_of_office/${outofofficeid}`, { headers: httpHeaders });
  }

  getGroupsById(id, token) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + id + "/groups");
    return this.http.get<any>(en_URI, { headers: httpHeaders });
  }

}
