import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleSearch',
})
export class SimpleSearchPipe implements PipeTransform {

  transform(value: any[], input: string) {
    if (!input) {
      return value;
    }
    return value.filter(item => {
      for (let field in item) {
        if (item[field] === null || item[field] === undefined) {
          continue;
        }
        if (item[field].toString().toLowerCase().indexOf(input.toString().toLowerCase()) !== -1) {
          return true;
        }
      }
      return false;
    });
  }
}
