import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AbilitiesService {
  public abilitiesSubject = new BehaviorSubject<any>('');
  public subscriptionSubject = new  BehaviorSubject<any>('');
  public accountTZSubject = new  BehaviorSubject<any>('');
  public closebannerSubject = new  BehaviorSubject<boolean>(false);
  public trialdaycountSubject = new  BehaviorSubject<any>(null);
  public expiryDateSubject = new  BehaviorSubject<string>('');
  public accountTZIDSubject = new  BehaviorSubject<string>('');

  updateAbilities(data) {
    this.abilitiesSubject.next(data);
  }

  updateSubscription(data){
    this.subscriptionSubject.next(data);
  }
  
  updateaccountTZ(data){
    this.accountTZSubject.next(data);
  }
  updateclosebanner(data){
    this.closebannerSubject.next(data);
  }
  updatetrialdaycount(data){
    this.trialdaycountSubject.next(data);
  }
  updateexpiryDate(data){
    this.expiryDateSubject.next(data);
  }
  updateaccountTZID(data){
    this.accountTZIDSubject.next(data);
  }
}
