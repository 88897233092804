import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { UserModel } from '../../../user-list/_models/user.models';

@Injectable({
  providedIn: 'root',
})
export class AttributeHTTPService {
  constructor(private http: HttpClient) { }

  getUserByToken(token, username): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + username);
    return this.http.get<UserModel>(en_URI, {
      headers: httpHeaders,
    });
  }

  updateAttributes(userId, token, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/users/${userId}/user_attributes`, params, {
      headers: httpHeaders,
    });
  }

  getUserAtrribute(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<UserModel>(`${environment.apiUrl}/user_attributes`, {
      headers: httpHeaders,
    });
  }

}
