import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AdminTopicsService {

  constructor(private http: HttpClient) { }

  getAllTopics(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/topics`, {
      headers: httpHeaders
    });
  }

  getAllPriority(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/priority_types`, {
      headers: httpHeaders
    });
  }

  getTopicByID(token, topicID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/topics/" + topicID);
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  getGroupByTID(token, topicID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/topics/" + topicID + "/groups");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  addTopic(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/topics`, params, {
      headers: httpHeaders,
    });
  }

  addGroupByTID(token, topicID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/topics/${topicID}/groups`, params, {
      headers: httpHeaders,
    });
  }

  updateTopic(token, topicID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/topics/${topicID}`, params, {
      headers: httpHeaders,
    });
  }

  deleteGroupByID(token, topicID, groupID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/topics/${topicID}/groups/${groupID}`, {
      headers: httpHeaders,
    });
  }

  deleteTopic(token, topicID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/topics/${topicID}`, {
      headers: httpHeaders,
    });
  }
}