import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { Limit } from './pipes/limit';
import { Stringlength } from './pipes/length';
import { SimpleSearchPipe } from './pipes/search.pipe';
import { JsonprettyPipe } from './pipes/jsonpretty.pipe';
import { DateparsePipe } from './pipes/dateparse.pipe';
import { AutofocusDirective } from './_base/curd/directive/auto-focus.directive';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AoIntlTelInputComponent } from './ao-intl-tel-input/ao-intl-tel-input.component';
import { NativeElementInjectorDirective } from './ao-intl-tel-input/directives/native-element-injector.directive';
export const dropdownModuleForRoot: ModuleWithProviders<BsDropdownModule> = BsDropdownModule.forRoot();

@NgModule({
  declarations: [ FirstLetterPipe, 
    Limit, 
    SimpleSearchPipe, 
    JsonprettyPipe, 
    DateparsePipe, 
    AutofocusDirective,
    Stringlength,
    AoIntlTelInputComponent,
    NativeElementInjectorDirective ],
  imports: [ CommonModule,
    FormsModule,
    ReactiveFormsModule,
    dropdownModuleForRoot ],
  exports: [ FirstLetterPipe, 
    Limit, 
    SimpleSearchPipe, 
    JsonprettyPipe, 
    DateparsePipe, 
    AutofocusDirective,
    Stringlength,
    AoIntlTelInputComponent,
    NativeElementInjectorDirective ],
})
export class CoreModule { }
