import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserProfileHTTPService {
  constructor(private http: HttpClient) { }

  editUser(name, token, params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.put(`${environment.apiUrl}/users/${name}/basic`, params, {
      headers: httpHeaders,
    });
  }

  timeZone(token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/app/time_zone`, { headers: httpHeaders });
  }

  locale(token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/app/locale`, { headers: httpHeaders });
  }

  getGroupsbyUser(token, userID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/users/${userID}/groups`, { headers: httpHeaders });
  }

  getWebcalFeedURL(token, userID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + userID + "/schedules/webcal");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  getICALExport(token, userID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/users/${userID}/schedules/export`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

}
