import { Injectable } from '@angular/core';
// RxJS
import { of, BehaviorSubject } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
// CRUD
import { QueryParamsModel } from '../../../_base/curd';
import { UserProfileHTTPService } from './user-profile-http/user-profile-http.services';

@Injectable()
export class UserProfileServices {
  private isLoadingSubject: BehaviorSubject<boolean>;

  lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
  constructor(
    private serProfileHTTPService: UserProfileHTTPService,
  ) { }

  editUser(name, token, params) {
    return this.serProfileHTTPService.editUser(name, token, params).pipe(
      map((res) => {
        return res;
      })
    );
  }

  timeZone(token) {
    return this.serProfileHTTPService.timeZone(token).pipe(
      mergeMap((res) => {
        return of(res);
      })
    );
  }

  locale(token) {
    return this.serProfileHTTPService.locale(token).pipe(
      mergeMap((res) => {
        return of(res);
      })
    );
  }


}
