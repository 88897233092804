// Angular
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
selector: 'app-kt-alert',
templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
@Input() type: 'primary | accent | warn';
@Input() duration = 0;
@Input() showCloseButton = true;
@Output() closed = new EventEmitter<boolean>();
alertShowing = true;
ngOnInit() {
if (this.duration === 0) {
return;
}

setTimeout(() => {
this.closeAlert();
}, this.duration);
}

closeAlert() {
this.closed.emit();
}
}
