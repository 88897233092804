import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { UserModel } from '../../_models/user.models';

@Injectable({
  providedIn: 'root',
})
export class UserHTTPService {
  constructor(private http: HttpClient) { }

  getAllUserList(token, roles?, search?, offset?, sortBy?, direction?, rolesBy?) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    param = param.append('include', 'roles');
    param = param.append('limit', '25');
    if (roles)
      param = param.append('role', roles);
    if (search)
      param = param.append('search', search);
    if (offset)
      param = param.append('offset', offset);
    if (sortBy)
      param = param.append('sortBy', sortBy);
    if (direction)
      param = param.append('sortDir', direction);
    if (rolesBy)
      param = param.append('include', rolesBy);
    return this.http.get<UserModel>(`${environment.apiUrl}/users`, {
      headers: httpHeaders, params: param
    });
  }
  getAllUserListWithoutparam(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    
    return this.http.get<any>(`${environment.apiUrl}/app/users`, {
      headers: httpHeaders
    });
  }
  deleteUser(name, token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<UserModel>(`${environment.apiUrl}/users/${name}`, {
      headers: httpHeaders,
    });
  }
  deleteUserrole(role_id, user_id, token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<UserModel>(`${environment.apiUrl}/roles/${role_id}/users/${user_id}`, {
      headers: httpHeaders,
    });
  }
  getUserTypes(token) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/app/user_types`, { headers: httpHeaders });
  }

  getUserRoles(token) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/app/roles`, { headers: httpHeaders });
  }
  getUserRolesbyId(token,user_id) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/users/${user_id}/roles`, { headers: httpHeaders });
  }
  getUserById(token, userId) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + userId);
    return this.http.get<any>(en_URI, { headers: httpHeaders });
  }

  usersExport(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/users/export`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

  addUserList(paeams = {}, token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/users`, paeams, {
      headers: httpHeaders,
    });
  }
  addNewRoletoUser(role_id, params = {}, user_id,token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/roles/${role_id}/users/${user_id}`, params, {
      headers: httpHeaders,
    });
  }
  importUsers(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/users/import`, params, {
      headers: httpHeaders, reportProgress: true, observe: 'events'
    });
  }

  //< Start Ebison  Added on 18/11/2021 //
  getLimit(token,params) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/limits/${params}`, {
      headers: httpHeaders,
    });
  }
  // Ebison  Added on 18/11/2021  End >//

  usersTemplateExport(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/users/export/template`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

  userisAdmin(token,userID) {
    let param = new HttpParams();
    param = param.append('user_id', userID)
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/IsUserGroupAdmin`, {
      headers: httpHeaders, params: param
    });
  }

  getIntegrationByUserId(token, userId) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + userId + "/inbound");
    return this.http.get<any>(en_URI, { headers: httpHeaders });
  }

  updateUserIntegration(token, params = {}, userId, inboundId): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + userId + "/inbound/" + inboundId);
    return this.http.put<any>(en_URI, params, {
      headers: httpHeaders,
    });
  }
}
