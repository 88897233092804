import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonpretty'
})
export class JsonprettyPipe implements PipeTransform {

  transform(val) {
    if(val) {
      let parseData = JSON.parse(val);
      return JSON.stringify(parseData, undefined, 4).replace(/ /g, '&nbsp;').replace(/\n/g, '<br/>');
    }
    else 
     return '';
  }

}
