import { environment } from '../../../../environments/environment';

export class AppHelper  {
 
  public static  getApiBaseUrl():string  {
      if(environment.apiUrlIsDynamic){
        let urlHost=window.location.hostname;
        let urlParts = urlHost.split('.');
        let apiUrl='';

        if(window.location.href.includes("https:"))
          apiUrl = 'https://';
        else
          apiUrl = 'http://';

        apiUrl+=urlParts[0]+'api.';
        for (let i = 1; i < urlParts.length; i++) {
          if(i !== urlParts.length-1)
            apiUrl += urlParts[i] + ".";
          else
            apiUrl += urlParts[i] ;
        }
        apiUrl+='/api/';

        return apiUrl;
      }
      else
      {
        return environment.baseUrl;
      }
  }
}
