import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  login(params = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/login`, params).pipe(
      mergeMap(res => {
        const result = res;
        return of(result);
      })
    );
  }

  // logout(token): Observable<any> {
  //   const httpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });
  //   const refreshToken = {
  //     "refresh_token": token
  //   };

  //   return this.http.post(`${API_USERS_URL}/auth/logout`, refreshToken, { headers: httpHeaders }).pipe(
  //     mergeMap(res => {
  //       const result = res;
  //       return of(result);
  //     })
  //   );
  // }

  logout(rtoken, token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    });
    const refreshToken = {
      "refresh_token": rtoken
    };
    return this.http.post<any>(`${environment.apiUrl}/auth/logout`, refreshToken, {
      headers: httpHeaders,
    });
  }

  ssologout(rtoken, token, account, userId): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-Type': 'application/json'
    });
    const Params = {
      "refresh_token": rtoken,
      "account":account,
      "name_id": userId,
    };
    return this.http.post<any>(`${environment.apiUrl}/auth/ssologout`, Params, {
      headers: httpHeaders,
    });
  }

  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${environment.apiUrl}/users`, user);
  }

  forgotPassword(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/resetpassword`, params).pipe(
      mergeMap(res => {
        const result = res;
        return of(result);
      })
    );
  }

  getAccount(params) {
    return this.http.post(`${environment.apiUrl}/auth/validateaccount`, params, {observe: 'response'});
  }
  
  getvalidatelogin(params) {
    return this.http.post(`${environment.apiUrl}/auth/validatelogin`, params, {observe: 'response'});
  }

  get Authorised_HttpOptions_JSON() {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return { headers: httpOptions };
  }

  getUserByToken(token, username): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + username);
    return this.http.get<UserModel>(en_URI, {
      headers: httpHeaders,
    });
  }

  getUserAttributes(token, username): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + username + "/user_attributes");
    return this.http.get<UserModel>(en_URI, {
      headers: httpHeaders,
    });
  }

  refreshToken(refreshToken): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(`${environment.apiUrl}/auth/refreshtoken`, refreshToken, {
      headers: httpHeaders,
    })
  }

  async refreshTokens(refreshToken) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return await this.http.post<any>(`${environment.apiUrl}/auth/refreshtoken`, refreshToken, {
      headers: httpHeaders,
    }).toPromise();
  }

  changePassword(name, token, params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.put(`${environment.apiUrl}/users/${name}/password`, params, {
      headers: httpHeaders,
    });
  }

  createPassword(params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/auth/createpassword`, params, {
      headers: httpHeaders,
    });
  }
  
  linkuser(params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/users/link`, params, {
      headers: httpHeaders,
    });
  }

  updatePassword(params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(`${environment.apiUrl}/auth/updatepassword`, params, {
      headers: httpHeaders,
    });
  }

  resendInvitation(name, token, params = {}) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`
    });
    return this.http.put<any>(`${environment.apiUrl}/users/${name}/invitation`, params, { headers: httpHeaders });
  }
  
  assignAlertByToken(params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/auth/assign/token`, params, {
      headers: httpHeaders,
    });
  }

  getAlertByToken(tokenType, account, token) {
      let param = new HttpParams();
      
      param = param.append('tokenType', tokenType);
      param = param.append('account', account);
      param = param.append('token', token);

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/auth/message/token`, {
      headers: httpHeaders, params: param
    });
  }

  acknowledgeAlertByToken(params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/auth/acknowledge/token`, params, {
      headers: httpHeaders,
    });
  }

  escalateAlertByToken(params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/auth/escalate/token`, params, {
      headers: httpHeaders,
    });
  }

  closeAlertByToken(params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/auth/close/token`, params, {
      headers: httpHeaders,
    });
  }

  activateAccount(params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.apiUrl}/auth/activate`, params, {
      headers: httpHeaders,
    });
  }
  
  getActivateAccount(param) {
    
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/auth/activate`, {
      headers: httpHeaders, 
      params:param
    });
  }

  getTimeZone() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/auth/time_zone`, {
      headers: httpHeaders
    });
  }

  getTimeZoneList() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.apiUrl}/app/time_zone`, {
      headers: httpHeaders
    });
  }
  
  refreshLogin(refreshToken): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/refreshlogin`, refreshToken).pipe(
      mergeMap(res => {
        const result = res;
        return of(result);
      })
    );
  }
  
  getVoiceMessage(token) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let en_URI = encodeURI(environment.apiUrl + "/alerts/voicemessage/" + token);
    return this.http.get(en_URI, {
      headers: httpHeaders
    });
  }
}
