// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { DynamicEnvironment } from "./dynamic-environment";

class Environment extends DynamicEnvironment {

  public production: boolean;
  public apiUrlIsDynamic: boolean;
  public appVersion: string;
  public USERDATA_KEY: string;
  public isMockEnabled: boolean;
  public accountKey: string;
  public baseUrl: string;
  public api_key: string;
  TOAST_MESSAGE_TIME: number;
  public applicationId: string;
  public clientToken: string;
  public show_tour_productid:string;
  public chat_tool_id:string;
  constructor() {
    super();
    this.production = false;
    this.apiUrlIsDynamic = false;
    this.appVersion = 'token';//v712demo2
    this.USERDATA_KEY = 'key';//authf649fc9a5f55
    this.isMockEnabled = true;
    this.accountKey = '';
    this.baseUrl = 'https://api.dev.alertops.com/api/v2';
    this.api_key = 'D46E8A14-9459-4320-A4EF-45996E66937F';
    this.applicationId = 'e4d50d15-1c2a-4bbc-af56-49fb97dab5d3';
    this.clientToken = 'pub9f48c4ffe0e3f17f84d31ceb430828a6';
    this.show_tour_productid='NX-f258bf1c';
    this.chat_tool_id ='vlcmxf37';
    // Constants
    this.TOAST_MESSAGE_TIME = 2000;
  }
}

export const environment = new Environment();


