import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { UserstorageService } from '../users/userstorage/userstorage.service';
dayjs.extend(LocalizedFormat);
@Pipe({
  name: 'dateparse'
})

export class DateparsePipe implements PipeTransform {
  userInfo:any;
  constructor(private _userstorageService:UserstorageService){
  this.userInfo = this._userstorageService.getuserstorage();
  }
  getLocale() {
    if(this.userInfo) {
      let details = JSON.parse(this.userInfo);
      return details.locale ? details.locale : 'en-US';
    }
  }
  
  transform(value: string, type:string): string {
    let locale = this.getLocale();
    if(value && type == 'long')
     return dayjs(value).locale(locale).format('L hh:mm:ss A');
     else if(value && type == 'medium')
     return dayjs(value).locale(locale).format('L hh:mm A')
     else if(value && type == 'short')
     return dayjs(value).locale(locale).format('L')
     else if(value && type == 'time')
     return dayjs(value).locale(locale).format('hh:mm A')
     else if(value && type == '24h')
     return dayjs(value).locale(locale).format('hh:mm A')
     else if(value && type == 'medium24')
     return dayjs(value).locale(locale).format('L HH:mm')
     else if(value && type == 'time24')
     return dayjs(value, 'h:mm A').format("L HH:mm")
     else
     return '';
  }

}
