import { Injectable } from "@angular/core";
import { DynamicHeaderMenuConfig } from '../../../../app/_theme/configs/dynamic-header-menu.config';

@Injectable({
    providedIn: "root"
})
export class UrlAccessService {
    private menu: any = DynamicHeaderMenuConfig;
    public simplifiedMenuItems: any;
    public url: any;
    
    getActualRedirectUrl(abilities,accName,currentUser) {
        if(abilities.user_typeid == 4) {
          this.url = '/' + accName + '/alerts';
        } else if(abilities.user_typeid == 3 && abilities.account_verified) {
            this.url = '/' + accName + '/subscription';
        } else {
          if (abilities.reporting_dashboards && abilities.alerts_list) {
            this.url = '/' + accName + '/dashboard';
          } else if (abilities.alerts_list) {
            this.url = '/' + accName + '/alerts';
          } else {
            this.url = '/' + accName + '/users/edit/' + currentUser['user_id'];
          }
        }
        return this.url;
    }
    getPermissionCheck(abilities) {
        const mainMenuItems = this.menu.items.flatMap(item => {
            if (item.submenu) {
              return item.submenu.map(subItem => ({
                page: subItem.page.replace('/',''),
                permissions: subItem.permissions
              }));
            } else {
              return {
                page: item.page.replace('/', ''),
                permissions: item.permissions
              };
            }
        });
        const integrationChildMenu = this.menu.integrationChildItems.flatMap(item => {
          return {
                page: item.page.replace('/', ''),
                permissions: item.permissions
          }
        })
        const administrationChildMenu = this.menu.administrationChildItems.flatMap(item => {
          return {
                page: item.page.replace('/', ''),
                permissions: item.permissions
          }
        });
        this.simplifiedMenuItems = [...mainMenuItems, ...integrationChildMenu, ...administrationChildMenu]
        let value: string;
        if (abilities.user_typeid == 3 || 
          abilities.user_typeid == 4 || 
          !abilities.reporting_dashboards ||  abilities.alerts_list  == 0) { value = 'dashboard'; this.filteredvalue(value) }
        if (abilities.user_typeid == 3 || abilities.alerts_list  == 0) { value = 'alerts'; this.filteredvalue(value) }
        if (abilities.users_list == 0) { value = 'users'; this.filteredvalue(value) }
        if (abilities.groups_list == 0 || abilities.user_typeid == 3 || abilities.user_typeid == 4) { value = 'groups-schedules'; this.filteredvalue(value) }
        if ((abilities.inbound_integrations == 0 && abilities.outbound_integrations_maintenance == 0)|| abilities.user_typeid == 3 || abilities.user_typeid == 4) { value = 'integrations'; this.filteredvalue(value) }
        if (!abilities.services || abilities.user_typeid == 3) { value = 'service-status'; this.filteredvalue(value) }
        if (abilities.services && (abilities.services_maintenance == 0 && abilities.services_subscriber == 0)) { value = 'service-status'; this.filteredvalue(value) }
        if (!abilities.workflows || abilities.workflows_list == 0 || abilities.user_typeid == 3 || abilities.user_typeid == 4) { value = 'workflows'; this.filteredvalue(value) }
        if (!abilities.call_routing || abilities.user_typeid == 3 || abilities.user_typeid == 4) { value = 'call-routing'; this.filteredvalue(value) }
        if (!abilities.escalation_policy_list || abilities.user_typeid == 3 || abilities.user_typeid == 4) { value = 'escalation-policies'; this.filteredvalue(value) }
        if (!abilities.reporting_dashboards || abilities.user_typeid == 3 || abilities.user_typeid == 4 || !abilities.reports_list) { value = 'reports'; this.filteredvalue(value) }
        if ((!abilities.topics && !abilities.bridges && !abilities.custom_alert_fields && 
          !abilities.user_attribute && (!abilities.roles && !abilities.roles_maintenance) && !abilities.postmortem) || (abilities.bridges_maintenance == 0 && 
            abilities.topics_maintenance == 0 &&
            abilities.custom_alert_fields_maintenance == 0 && 
            abilities.user_attributes_maintenance == 0&&
            abilities.roles_maintenance == 0
            ) || abilities.user_typeid == 3 || abilities.user_typeid == 4
             ) { value = 'administration'; this.filteredvalue(value) }
        if (abilities.billing_administration == 0 || !abilities.support_billing) { value = 'billing'; this.filteredvalue(value)}
        if (abilities.billing_administration == 0 || !abilities.support_billing || abilities.account_verified == 0) { value = 'subscription'; this.filteredvalue(value)}
        if (abilities.account_settings == 0) { value = 'account-settings'; this.filteredvalue(value)}
        // for Integration Details
        if (abilities.inbound_integrations == 0) { value = 'inbound'; this.filteredvalue(value)}
        // Outbound Details, Creation, method
        if (abilities.outbound_integrations_maintenance == 0) { value = 'create-outbound'; this.filteredvalue(value)}
        if (abilities.outbound_integrations_maintenance == 0) { value = 'edit-outbound'; this.filteredvalue(value)}
        if (abilities.outbound_integrations_maintenance == 0) { value = 'outbound-method'; this.filteredvalue(value)}
        // Inbound log details
        if (abilities.inbound_integrations == 0) { value = 'message-detail'; this.filteredvalue(value)}//For inbound log details
        // For maintenance window
        if (abilities.advanced_integration == 0) { value = 'maintenance'; this.filteredvalue(value)}
        // For template
        if (abilities.inbound_integrations == 0) { value = 'template'; this.filteredvalue(value)}
        if (!abilities.custom_alert_fields_maintenance || !abilities.custom_alert_fields) { value = 'custom-alert'; this.filteredvalue(value)}
        if (!abilities.roles_maintenance) { value = 'roles'; this.filteredvalue(value)}
        if (!abilities.topics_maintenance || !abilities.topics) { value = 'topic'; this.filteredvalue(value)}
        return this.simplifiedMenuItems;
    }

    filteredvalue(value) {
        this.simplifiedMenuItems.map(item => {
          if(item.page == value) {
            item.permissions = true;
          }
        })
    }
}