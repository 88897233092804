import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GroupAcessService {

  constructor(private http: HttpClient) { }

  getAcessByID(token, groupID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/groups/" + groupID + "/access");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }
  changeAccess(token, grpID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    return this.http.put<any>(`${environment.apiUrl}/groups/${grpID}/access`, params, {
      headers: httpHeaders,
    });
  }
  getAcessAdmin(token, groupID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/groups/${groupID}/access/admins_available`, {
      headers: httpHeaders
    });
  }

  addAcessAdmin(token, groupID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/groups/${groupID}/access/admins`, params, {
      headers: httpHeaders,
    });
  }

  deleteAcess(token, groupID, body): Observable<any> {
    const data = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
      body: body
    }
    return this.http.delete<any>(`${environment.apiUrl}/groups/${groupID}/access/admins`, data);
  }

  switchOwner(token, groupID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/groups/${groupID}/access/owner`, params, {
      headers: httpHeaders,
    });
  }
}
