import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WorkflowAccessService {

  constructor(private http: HttpClient) { }

  getAllWorkflowAcess(token, workflowID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/workflows/" + workflowID + "/access");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  getAllWorkflowAdmin(token, workflowID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/workflows/${workflowID}/access/admins_available`, {
      headers: httpHeaders
    });
  }

  getAllUsers(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/users`, {
      headers: httpHeaders
    });
  }

  changeAccess(token, workflowID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    return this.http.put<any>(`${environment.apiUrl}/workflows/${workflowID}/access`, params, {
      headers: httpHeaders,
    });
  }

  switchOwner(token, workflowID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    return this.http.put<any>(`${environment.apiUrl}/workflows/${workflowID}/access/owner`, params, {
      headers: httpHeaders,
    });
  }

  addWorkflowAcessAdmin(token, workflowID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    return this.http.post<any>(`${environment.apiUrl}/workflows/${workflowID}/access/admins`, params, {
      headers: httpHeaders,
    });
  }

  deleteWorkflowAccess(token, workflowID, param): Observable<any> {
    const data = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
      body: param
    }
    return this.http.delete<any>(`${environment.apiUrl}/workflows/${workflowID}/access/admins`, data);
  }
}


