import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashScreenModule } from './_theme/partials/layout/splash-screen/splash-screen.module';
import { CurdModule } from './_theme/partials/content/crud/curd.module';
import { HttpUtilsService, InterceptService, LayoutUtilsService, TypesUtilsService } from './_theme/core/_base/curd';
import { ScheduleServices } from './_theme/core/users/schedules/_services/schedules.services';
import { AttributeServices } from './_theme/core/users/attribute/_services/attribute.services';
import { ContactMethodServices } from './_theme/core/users/contact_method/_services/contact-method.services';
import { OutOfOfficeServices } from './_theme/core/users/out_of_office/_services/out_of_office.services';
import { UserProfileServices } from './_theme/core/users/user_profile/_services/user-profile.services';
import { UserServices } from './_theme/core/users/user-list/_services/user.services';
import { GroupAcessService, GroupMemberService, GroupsService, SchedulesService } from './_theme/core/groups-schedule';
import { WorkflowAccessService, WorkflowActionService, WorkflowConditionService, WorkflowListService, WorkflowUsageService } from './_theme/core/workflows';
import { AdminBridgeService, AdminRolesService, AdminTopicsService } from './_theme/core/administration';
import { AppInitService } from './_theme/core/app-init/app-init.service';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import arDZ from "@angular/common/locales/ar-DZ"
import arBH from "@angular/common/locales/ar-BH"
import arEG from "@angular/common/locales/ar-EG"
import arIQ from "@angular/common/locales/ar-IQ"
import arJO from "@angular/common/locales/ar-JO"
import arKW from "@angular/common/locales/ar-KW"
import arLB from "@angular/common/locales/ar-LB"
import arLY from "@angular/common/locales/ar-LY"
import arMA from "@angular/common/locales/ar-MA"
import arOM from "@angular/common/locales/ar-OM"
import arQA from "@angular/common/locales/ar-QA"
import arSA from "@angular/common/locales/ar-SA"
import arSD from "@angular/common/locales/ar-SD"
import arSY from "@angular/common/locales/ar-SY"
import arTN from "@angular/common/locales/ar-TN"
import arAE from "@angular/common/locales/ar-AE"
import arYE from "@angular/common/locales/ar-YE"
import nlBE from "@angular/common/locales/nl-BE"
import enAU from "@angular/common/locales/en-AU"
import enCA from "@angular/common/locales/en-CA"
import enIN from "@angular/common/locales/en-IN"
import enIE from "@angular/common/locales/en-IE"
import enMT from "@angular/common/locales/en-MT"
import enNZ from "@angular/common/locales/en-NZ"
import enPH from "@angular/common/locales/en-PH"
import enSG from "@angular/common/locales/en-SG"
import enZA from "@angular/common/locales/en-ZA"
import enGB from "@angular/common/locales/en-GB"
import frBE from "@angular/common/locales/fr-BE"
import frCA from "@angular/common/locales/fr-CA"
import frLU from "@angular/common/locales/fr-LU"
import frCH from "@angular/common/locales/fr-CH"
import deAT from "@angular/common/locales/de-AT"
import deLU from "@angular/common/locales/de-LU"
import deCH from "@angular/common/locales/de-CH"
import elCY from "@angular/common/locales/el-CY"
import itCH from "@angular/common/locales/it-CH"
import ptPT from "@angular/common/locales/pt-PT"
import esAR from "@angular/common/locales/es-AR"
import esBO from "@angular/common/locales/es-BO"
import esCL from "@angular/common/locales/es-CL"
import esCO from "@angular/common/locales/es-CO"
import esCR from "@angular/common/locales/es-CR"
import esDO from "@angular/common/locales/es-DO"
import esEC from "@angular/common/locales/es-EC"
import esSV from "@angular/common/locales/es-SV"
import esGT from "@angular/common/locales/es-GT"
import esHN from "@angular/common/locales/es-HN"
import esMX from "@angular/common/locales/es-MX"
import esNI from "@angular/common/locales/es-NI"
import esPA from "@angular/common/locales/es-PA"
import esPY from "@angular/common/locales/es-PY"
import esPE from "@angular/common/locales/es-PE"
import esPR from "@angular/common/locales/es-PR"
import esUS from "@angular/common/locales/es-US"
import esUY from "@angular/common/locales/es-UY"
import esVE from "@angular/common/locales/es-VE"
import bg from "@angular/common/locales/bg"
import zh from "@angular/common/locales/zh"
import hr from "@angular/common/locales/hr"
import da from "@angular/common/locales/da"
import nl from "@angular/common/locales/nl"
import et from "@angular/common/locales/et"
import fi from "@angular/common/locales/fi"
import fr from "@angular/common/locales/fr"
import de from "@angular/common/locales/de"
import el from "@angular/common/locales/el"
import hu from "@angular/common/locales/hu"
import it from "@angular/common/locales/it"
import ja from "@angular/common/locales/ja"
import ko from "@angular/common/locales/ko"
import lv from "@angular/common/locales/lv"
import lt from "@angular/common/locales/lt"
import pl from "@angular/common/locales/pl"
import pt from "@angular/common/locales/pt"
import ro from "@angular/common/locales/ro"
import ru from "@angular/common/locales/ru"
import sk from "@angular/common/locales/sk"
import sl from "@angular/common/locales/sl"
import es from "@angular/common/locales/es"
import sv from "@angular/common/locales/sv"
import tr from "@angular/common/locales/tr"
import  'dayjs/locale/en';
import 'dayjs/locale/ar';
import 'dayjs/locale/ar-DZ';
import 'dayjs/locale/ar-IQ';
import 'dayjs/locale/ar-KW';
import 'dayjs/locale/ar-LY';
import 'dayjs/locale/ar-MA';
import 'dayjs/locale/ar-SA';
import 'dayjs/locale/ar-TN';
import 'dayjs/locale/nl-BE';
import 'dayjs/locale/en-AU';
import 'dayjs/locale/en-CA';
import 'dayjs/locale/en-IN';
import 'dayjs/locale/en-IE';
import 'dayjs/locale/en-NZ';
import 'dayjs/locale/en-SG';
import 'dayjs/locale/en-GB';
import 'dayjs/locale/fr-CA';
import 'dayjs/locale/fr-CH';
import 'dayjs/locale/de-AT';
import 'dayjs/locale/de-CH';
import 'dayjs/locale/it-CH';
import 'dayjs/locale/es-DO';
import 'dayjs/locale/es-MX';
import 'dayjs/locale/es-PR';
import 'dayjs/locale/es-US';
import  'dayjs/locale/zh-cn';
import 'dayjs/locale/bg';
import 'dayjs/locale/zh';
import 'dayjs/locale/hr';
import 'dayjs/locale/da';
import 'dayjs/locale/nl';
import 'dayjs/locale/et';
import 'dayjs/locale/fi';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/hu';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/lv';
import 'dayjs/locale/lt';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sk';
import 'dayjs/locale/sl';
import 'dayjs/locale/es';
import 'dayjs/locale/sv';
import 'dayjs/locale/tr';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './_theme/core/services/lowecase-url-serializer.service';

registerLocaleData(bg)
registerLocaleData(zh)
registerLocaleData(hr)
registerLocaleData(da)
registerLocaleData(nl)
registerLocaleData(et)
registerLocaleData(fi)
registerLocaleData(fr)
registerLocaleData(de)
registerLocaleData(el)
registerLocaleData(hu)
registerLocaleData(it)
registerLocaleData(ja)
registerLocaleData(ko)
registerLocaleData(lv)
registerLocaleData(lt)
registerLocaleData(pl)
registerLocaleData(pt)
registerLocaleData(ro)
registerLocaleData(ru)
registerLocaleData(sk)
registerLocaleData(sl)
registerLocaleData(es)
registerLocaleData(sv)
registerLocaleData(tr)
registerLocaleData(arDZ)
registerLocaleData(arBH)
registerLocaleData(arEG)
registerLocaleData(arIQ)
registerLocaleData(arJO)
registerLocaleData(arKW)
registerLocaleData(arLB)
registerLocaleData(arLY)
registerLocaleData(arMA)
registerLocaleData(arOM)
registerLocaleData(arQA)
registerLocaleData(arSA)
registerLocaleData(arSD)
registerLocaleData(arSY)
registerLocaleData(arTN)
registerLocaleData(arAE)
registerLocaleData(arYE)
registerLocaleData(nlBE)
registerLocaleData(enAU)
registerLocaleData(enCA)
registerLocaleData(enIN)
registerLocaleData(enIE)
registerLocaleData(enMT)
registerLocaleData(enNZ)
registerLocaleData(enPH)
registerLocaleData(enSG)
registerLocaleData(enZA)
registerLocaleData(enGB)
registerLocaleData(frBE)
registerLocaleData(frCA)
registerLocaleData(frLU)
registerLocaleData(frCH)
registerLocaleData(deAT)
registerLocaleData(deLU)
registerLocaleData(deCH)
registerLocaleData(elCY)
registerLocaleData(itCH)
registerLocaleData(ptPT)
registerLocaleData(esAR)
registerLocaleData(esBO)
registerLocaleData(esCL)
registerLocaleData(esCO)
registerLocaleData(esCR)
registerLocaleData(esDO)
registerLocaleData(esEC)
registerLocaleData(esSV)
registerLocaleData(esGT)
registerLocaleData(esHN)
registerLocaleData(esMX)
registerLocaleData(esNI)
registerLocaleData(esPA)
registerLocaleData(esPY)
registerLocaleData(esPE)
registerLocaleData(esPR)
registerLocaleData(esUS)
registerLocaleData(esUY)
registerLocaleData(esVE)

function init_app(appLoadService: AppInitService) {
  return () => {
    return new Promise((resolve) => {
      appLoadService.init().subscribe().add(resolve);
    });
  };
}

export function baseHRefFactory(): Function {
  return window['_app_base'];
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    CurdModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    },
    LayoutUtilsService,
    TypesUtilsService,
    ScheduleServices,
    AttributeServices,
    ContactMethodServices,
    OutOfOfficeServices,
    UserProfileServices,
    UserServices,
    HttpUtilsService,
    GroupsService,
    GroupMemberService,
    SchedulesService,
    GroupAcessService,
    WorkflowListService,
    WorkflowActionService,
    WorkflowConditionService,
    WorkflowAccessService,
    AdminTopicsService,
    AdminRolesService,
    AdminBridgeService,
    WorkflowUsageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }