import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppHelper } from '../../../_helpers';
import { Router } from '@angular/router';
// RxJS
import { Observable, of, BehaviorSubject } from 'rxjs';
import { mergeMap, map, catchError, finalize } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/curd';
import { environment } from '../../../../../../environments/environment';
import { AttributeHTTPService } from './attribute-http/attribute-http.services';

const API_BASE_URL = `${AppHelper.getApiBaseUrl()}`;


@Injectable()
export class AttributeServices {
  private isLoadingSubject: BehaviorSubject<boolean>;

  lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
  constructor(
    private attributeHTTPService: AttributeHTTPService,
  ) { }

  getUserById(token, username) {
    return this.attributeHTTPService.getUserByToken(token, username).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

  updateAttributes(userId, token, params) {
    return this.attributeHTTPService.updateAttributes(userId, token, params).pipe(
      map((user) => {
        return of(user);
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }

  getUserAtrributes(token) {
    return this.attributeHTTPService.getUserAtrribute(token).pipe(
      mergeMap((res) => {
        const data = res;
        return of(data);
      })
    );
  }

}
